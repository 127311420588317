import React from 'react';
import '../assets/scss/footer.scss';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <ul className="footer-list">
          <li className="footer-list__item">乐聚（深圳）机器人技术有限公司</li>
          <li className="footer-list__item">
            Copyright@2016-{new Date().getFullYear()} Leju 乐聚 版权所有
          </li>
          <li className="footer-list__item">
            <a href="http://www.miitbeian.gov.cn"> 粤ICP备16048266号 </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
